export enum IdentityGender {
  MALE = 1,
  FEMALE = 2,
}

export const LocalPermissions = {
  MANAGE_CMT: 'manage-cmt',
  MANAGE_CMT_GENERAL: 'manage-general',

  MANAGE_BANNERS: 'manage-offers-banners',
  MANAGE_BANNERS_CREATE: 'manage-offers-banners-create',

  MANAGE_OFFERS: 'manage-offers-offers',
  MANAGE_OFFERS_POPULAR_OFFERS: 'manege-offers-popular-offers',
  MANAGE_OFFERS_CUSTOM_CATEGORY_OFFERS: 'manage-offers-custom-category-offers',
  MANAGE_OFFERS_ONBOARDING_OFFERS: 'manege-offers-onboarding-offers',
  MANAGE_OFFERS_OFFERS_MANAGE_ID: 'manage-offers-offers-manage-id',
  MANAGE_OFFERS_OFFERS_CREATE: 'manage-offers-offers-create',
  MANAGE_OFFERS_OFFERS_BULK: 'manage-offers-offers-bulk',
  MANAGE_OFFERS_BRANCHES_BULK: 'manage-offers-branches-bulk',

  MANAGE_AFFILIATE: 'manage-affiliate',
  MANAGE_AFFILIATE_MERCHANTS: 'manage-affiliate-merchants',
  MANAGE_AFFILIATE_TRANSACTIONS: 'manage-affiliate-transactions',
  MANAGE_AFFILIATE_CONTEST: 'manage-affiliate-contest',
  MANAGE_AFFILIATE_MERCHANTS_ID: 'manage-affiliate-merchants-id',
  MANAGE_AFFILIATE_CATEGORIES: 'manage-affiliate-categories',
  MANAGE_AFFILIATE_MERCHANTS_POPULAR: 'manage-affiliate-merchants-popular',
  MANAGE_AFFILIATE_BANNERS: 'manage-affiliate-banners',
  MANAGE_AFFILIATE_CASHOUT: 'manage-affiliate-cashout',
  MANAGE_AFFILIATE_CASHOUT_ID: 'manage-affiliate-cashout-id',

  MANAGE_FINANCING_APPLICATIONS: 'manage-financing-applications',
  MANAGE_FINANCING_REPAYMENTS_UPLOAD: 'manage-financing-repayments-upload',
  MANAGE_FINANCING_APPLICATION_REVIEWS: 'manage-financing-application-reviews',
  MANAGE_FINANCING_APPLACATIONS_PREFILL_CONTRACT:
    'manage-applacations-prefill-contract',
  MANAGE_FINANCING_APPLACATIONS_PREFILL_CONTRACT_ID:
    'manage-applacations-prefill-contract-id',
  MANAGE_FINANCING_APPLACATIONS_PREFILL_CONNECT_CONTRACT:
    'manage-applacations-prefill-connect-contract',
  MANAGE_FINANCING_APPLACATIONS_PREFILL_CONNECT_CONTRACT_ID:
    'manage-applacations-prefill-connect-contract-id',
  MANAGE_FINANCING_APPLICATIONS_PROFILE_VALIDATION:
    'manage-applications-profile-validation',
  MANAGE_FINANCING_APPLICATIONS_PROFILE_VALIDATION_ID:
    'manage-applications-profile-validation-id',
  MANAGE_FINANCING_APPLICATIONS_ASSIGNED_PARTNER:
    'manage-financing-applications-assigned-partner',
  MANAGE_FINANCING_APPLICATIONS_UNBLOCK_USER:
    'manage-financing-applications-unblock-user',

  MANAGE_CONTRACTS_MARK_AS_SIGNED: 'manage-contracts-mark-as-signed',

  MANAGE_GENERAL_APP_USERS: 'manage-app-users',
  MANAGE_GENERAL_FEATURE_FLAGS: 'manage-feature-flags',
  MANAGE_GENERAL_FILES: 'manage-general-files',
  MANAGE_GENERAL_PLUS: 'manage-general-plus',
  MANAGE_GENERAL_USERS_MANAGE: 'manage-general-users-manage',

  MANAGE_MERCHANT: 'manage-merchant',
  MANAGE_MERCHANT_BULK: 'manage-merchant-bulk',
  MANAGE_MERCHANT_CATI_BULK: 'manage-merchant-cati-bulk',
};

export const RegexPassword = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
);

export enum BulkFileUploadStrategy {
  CATI_STRATEGY = 'CATIStrategy',
  CARD_OFFER_STRATEGY = 'CardOfferStrategy',
  ANIT_MONEY_LAUNDERING_STRATEGY = 'AntiMoneyLaunderingStrategy',
  BANK_BLACKLIST_STRATEGY = 'BankStrategy',
  MERCHANT_STRATEGY = 'MerchantStrategy',
  REPAYMENTS_STRATEGY = 'RepaymentsStrategy',
  APPLICATION_REVIEWS_STRATEGY = 'ApplicationReviewsStrategy',
}
